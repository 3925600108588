.mapContainer {
    width: 100%;
    height: 100%;
}

.mapboxgl-canvas {
    position: absolute;
}

.mapboxgl-ctrl-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0 6px 12px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    padding: 0 5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
}

.mapboxgl-marker {
    width: 25px;
    position: absolute;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup-close-button {
    font-size: 16px;             /* Légèrement agrandi pour une meilleure lisibilité */
    color: #333;                 /* Couleur sombre par défaut */
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s ease;
    position: absolute;
    top: 5px;
    right: 5px;
}
  
.mapboxgl-popup-close-button:hover {
    color: #e74c3c;             /* Passage au rouge doux au survol */
}
  
.mapboxgl-popup-content {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 20px 25px;         /* Augmentation de l'espace intérieur pour une meilleure lisibilité */
    min-width: 120px;           /* Légère augmentation de la largeur minimale */
    border-radius: 8px;         /* Bords plus arrondis */
    background-color: #fff;     /* Fond blanc pour un contraste optimal */
    color: #333;                /* Texte en couleur sombre */
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    line-height: 1.4;
}
  
.mapboxgl-popup-content-wrapper {
    padding: 1%;
}